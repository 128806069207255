// eslint-disable

const KlGraph = () => import("./osint-graph/KlGraph.vue");
export default {
  components: {
    KlGraph,
  },
  props: {
    graphData: Array,
    childComponent: Boolean,
    caseId:String,
    mainEntity:Array,
    graphLoading: Boolean,
    loaderProgress: Number
  },
  data() {
    return {
    };
  },
  mounted() {
    
  },
  computed: {
    getGraphData(){
      if(this.graphData){
        return this.graphData;
      }
    },
    getGraphLoading(){
      return this.graphLoading
    }
  },
  methods: {
   
  },
  watch:{
    
  }
};